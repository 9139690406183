import React, { Fragment, useEffect, useMemo, useState } from "react";
import MenuListItem from "../../../common/Menu/components/MenuListItem/MenuListItem";
import LogoutFilled from "../../../common/Icons/LogoutFilled";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { useStyles } from "./AdminButtons.style";
import LoginFilled from "../../../common/Icons/LoginFilled";
import MenuList from "../../../common/Menu/components/MenuList/MenuList";
import { redirectToPage } from "../../../utils/helper";
import withConfig from "../../../utils/withConfig";
import SavedDraftsModal from "../../Modals/SavedDraftsModal/SavedDraftsModal";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import theme from "../../../common/Theme/themes/default";
import LogInPopUp from "../../LogInPopUp/LogInPopUp";
import { array, bool, object } from "prop-types";

const AdminButtons = ({
  currentUser,
  adminMenuItems,
  townsWithClusters,
  isPathUserSignIn,
}) => {
  const classes = useStyles();
  const {
    palette: {
      themeColor: { primary },
    },
  } = theme;
  const userHasSavedDrafts = useMemo(() => {
    return adminMenuItems.find((item) => item.code === "drafts") ? true : false;
  }, [adminMenuItems]);
  const [openDrafts, setOpenDrafts] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const handleItemClick = (action) => {
    if (action.path !== "") {
      redirectToPage(action.path, "_top");
      return;
    }

    if (action.code === "drafts") {
      setOpenDrafts(true);
      return;
    }

    return;
  };

  useEffect(() => {
    if (isPathUserSignIn) {
      setOpenLogin(true);
    }

    return () => setOpenLogin(false);
  }, []);

  if (currentUser && adminMenuItems?.length > 0) {
    return (
      <MenuList disablePadding={true} classes={{ root: classes.admin_list }}>
        {adminMenuItems?.map((action, index) => (
          <Fragment key={"admin-menu-item-" + index}>
            <MenuListItem
              classes={{
                root: index === 0 ? classes.first_admin_item : "",
                gutters: classes.menu_items_gutters,
              }}
              divider={false}
              disableGutters={false}
              onClick={() => handleItemClick(action)}
            >
              <Typography color="white" level="t3_text_3">
                {action.label}
              </Typography>
            </MenuListItem>
          </Fragment>
        ))}

        <MenuListItem
          classes={{
            root: classes.logout_item,
            gutters: classes.menu_items_gutters,
          }}
          divider={false}
          disableGutters={false}
          onClick={() => redirectToPage(withConfig("SIGN_OUT"), "_top")}
        >
          <Typography color="white" level="paragraph_1">
            {labels.LOGOUT}
          </Typography>
          <LogoutFilled />
        </MenuListItem>
      </MenuList>
    );
  }

  return (
    <>
      <MenuList disablePadding={true} classes={{ root: classes.admin_list }}>
        <MenuListItem
          classes={{
            root: classes.login_item,
            gutters: classes.menu_items_gutters,
          }}
          divider={false}
          disableGutters={false}
          onClick={() => setOpenLogin(true)}
        >
          <Typography color="white" level="paragraph_1">
            {labels.LOGIN}
          </Typography>
          <LoginFilled />
        </MenuListItem>
      </MenuList>

      <PopUpDialog
        onClose={() => setOpenLogin(false)}
        openState={openLogin}
        title={""}
        content={<LogInPopUp townsWithClusters={townsWithClusters || []} />}
        closeButtonColor={primary}
        titleClass={classes.login_dialog_title}
        closeButtonClass={classes.login_close_button}
        className={classes.login_popup}
        disableCloseOnBackDropClick={true}
      />
    </>
  );
};

AdminButtons.propTypes = {
  currentUser: object,
  adminMenuItems: array,
  townsWithClusters: array,
  isPathUserSignIn: bool,
};

export default AdminButtons;
