import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getTopStories, getDisplayBlocks } from "../services/subsectionService";
import { getVideoAd, getSponsors } from "../services/homePage";
import { AD_PLACEHOLDER } from "../utils/constants/homepageConstants";

const SubsectionContext = React.createContext({});

const SubsectionContextProvider = ({ children }) => {
    const [section, setSection] = useState();
    const [town, setTown] = useState();
    const [subsection, setSubsection] = useState();
    const [loading, setLoading] = useState(false);
    const [leftBlocks, setLeftBlocks] = useState([]);
    const [rightBlocks, setRightBlocks] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [videoAd, setVideoAd] = useState(null);
    const [localNews, setLocalNews] = useState({
      mainStory: undefined,
      secondaryStories: [],
    });
  
    useEffect(() => {
      const loadVideoAd = async () => {
        const response = await getVideoAd(town.id);
        if (response && response?.status === 200) {
          setVideoAd(response?.data?.video_ad_html);
        }
      };
  
      const loadSponsors = async () => {
        const response = await getSponsors(town.mongo_id);
        if (response && response?.status === 200) {
          setSponsors(response?.data?.sponsors);
        }
      };
      if (town) {
        loadVideoAd();
        loadSponsors();
      }
    }, [town]);
  
    useEffect(() => {
      const loadLocalNews = async () => {
        setLoading(true);
        const response = await getTopStories(town ? town.id : null, section ? section.id : null, subsection ? subsection.id : null);
        if (
          response &&
          response?.status === 200 &&
          response?.data?.top_stories?.length > 0
        ) {
          const topStoriesCount = response.data.top_stories.length;
          const placeholderForAd = { title: AD_PLACEHOLDER, id: 0 };
  
          setLocalNews({
            mainStory: response.data.top_stories[0],
            secondaryStories:
              topStoriesCount === 1
                ? [placeholderForAd]
                : response.data.top_stories.slice(1, topStoriesCount),
          });
        }
        setLoading(false);
      };
      const loadDisplayBlocks = async () => {
        const response = await getDisplayBlocks(
          town ? town.id : null,
          section ? section.id : null,
          subsection.id
        );
        if (response && response?.status === 200) {
          const display_blocks = response?.data?.display_blocks;
          setLeftBlocks(display_blocks.left_blocks);
          setRightBlocks(
            display_blocks.right_blocks ? display_blocks.right_blocks : []
          );
        }
      };
  
      if (subsection) {
        loadLocalNews();
        loadDisplayBlocks();
      }
    }, [subsection]);
  
    return (
      <SubsectionContext.Provider
        value={{
          setSection,
          setTown,
          setSubsection,
          localNews,
          leftBlocks,
          rightBlocks,
          sponsors,
          videoAd,
          town,
          subsection,
          loading,
        }}
      >
        {children}
      </SubsectionContext.Provider>
    );
  };
  
  SubsectionContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  
  const useSubsection = () => {
    const SubsectionContextValues = useContext(SubsectionContext);
  
    if (!SubsectionContextValues) {
      throw new Error(
        "useContext must be used within a descendant of the SubsectionContextProvider"
      );
    }
  
    return SubsectionContextValues;
  };
  
  const withSubsectionContextProvider = (Component) => {
    const ComponentWithProvider = (props) => (
      <SubsectionContextProvider>
        <Component {...props} />
      </SubsectionContextProvider>
    );
  
    return ComponentWithProvider;
  };
  
  export { useSubsection, withSubsectionContextProvider };
  