import React from "react";
import clsx from "clsx";
import Grid from "../../../../../common/Grid/Grid";
import { useTownTheme } from "../../../../../context/TownThemeContext";
import MasterheadLogo from "../MasterheadLogo";
import { useStyles } from "./ReducedVersion.style";
import { bool, object, string } from "prop-types";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import { useMastheadContext } from "../../../../../context/MastheadContext";
import TopNavBar from "../../../../TopNavBarLicenses/TopNavBar";

const GRAY = "#9C9C9C";

const ReducedVersion = ({
  townSlug,
  section,
  currentUser,
  displayWeather,
  sponsorInfo,
  isWeatherClickable,
  legalNoticePermissions,
  isPathUserSignIn,
}) => {
  const classes = useStyles();
  const { mastheadInfo } = useMastheadContext();
  const { town } = useTownTheme();
  const isMobile = screenWidthIsLowerThan(values.md);

  return (
    <Grid
      style={{
        backgroundColor: "white",
      }}
      container
      justifyContent={isMobile ? "space-between" : "space-evenly"}
      className={clsx(classes.reduced_masterhead, classes.no_printing)}
    >
      <Grid className={classes.grid_item} item xs={12}>
        <MasterheadLogo
          townName={mastheadInfo?.name_for_header}
          townSlug={townSlug}
          sectionSlug={section?.slug}
          displaySlogan={false}
          logoUrl={
            town?.slug === "halston-media"
              ? "https://tapinto-production.s3.amazonaws.com/assets/images/halston-media-reduced-logo.png"
              : mastheadInfo?.logo_image
          }
          reduced
        />
      </Grid>

      <Grid item xs={12}>
        <TopNavBar
          townSlug={townSlug}
          section={section}
          currentUser={currentUser}
          isPathUserSignIn={isPathUserSignIn}
          origin="Reduced"
        />
      </Grid>
    </Grid>
  );
};

ReducedVersion.propTypes = {
  townSlug: string,
  section: object,
  currentUser: object,
  displayWeather: bool,
  sponsorInfo: object,
  isWeatherClickable: bool,
  legalNoticePermissions: object,
  isPathUserSignIn: bool,
};

export default ReducedVersion;
