import React, { useMemo } from "react";
import Grid from "../../../../../common/Grid/Grid";
import SecondaryStoriesContainer from "../../SecondaryStoriesContainer/SecondaryStoriesContainer";
import { useTown } from "../../../../../context/TownContext";
import VerticalCard from "../../VerticalCard/VerticalCard";
import VideoAd from "../../../../Ad/VideoAd";
import AdCard from "../../../../Homepage/AdCard/AdCard";
import SubscriptionBox from "../../../../SubscriptionBox/SubscriptionBox";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import { useStyles } from "./Layout5.style";
import Header from "../../SecondaryStoriesContainer/Header/Header";

const Layout5 = ({ adTargets, videoAd, localNews, hasLockedBullseyeAd }) => {
  const classes = useStyles();
  const { town } = useTown();
  const { mainStory, secondaryStories } = localNews || {};
  const isLargeScreen = screenWidthIsLowerThan(1380);
  const isMediumScreen = screenWidthIsLowerThan(1025);
  const itemWidth = useMemo(() => {
    if (isMediumScreen) {
      return {
        lateral: 12,
        middle: 12,
        spacing: 0,
      };
    }

    return {
      lateral: isLargeScreen ? 4 : 3,
      middle: isLargeScreen ? 4 : 5,
      spacing: isLargeScreen ? 3 : 0,
    };
  }, [isLargeScreen, isMediumScreen]);

  return (
    <Grid
      container
      spacing={itemWidth?.spacing}
      justifyContent="space-between"
      classes={{ root: classes.main_container_layout_5 }}
    >
      {isMediumScreen ? (
        <Grid xs={itemWidth?.middle} item>
          <Header variant="layout_5" />
          <VerticalCard contents={mainStory} variant="layout_5" />
        </Grid>
      ) : (
        <>
          {secondaryStories?.length > 0 && (
            <Grid xs={itemWidth?.lateral} item>
              <SecondaryStoriesContainer
                variant="layout_5"
                stories={secondaryStories}
                options={{ truncatedHeader: true }}
              />
            </Grid>
          )}
        </>
      )}

      {isMediumScreen ? (
        <>
          {secondaryStories?.length > 0 && (
            <Grid xs={itemWidth?.lateral} item>
              <SecondaryStoriesContainer
                variant="layout_5"
                stories={secondaryStories}
                options={{ truncatedHeader: true }}
              />
            </Grid>
          )}
        </>
      ) : (
        <Grid
          xs={itemWidth?.middle}
          item
          classes={{ root: classes.main_story }}
        >
          <VerticalCard contents={mainStory} variant="layout_5" />
        </Grid>
      )}

      <Grid
        xs={itemWidth?.lateral}
        item
        classes={{ root: classes.top_stories_sidebar }}
      >
        {videoAd && Object.keys(videoAd).length > 0 ? (
          <VideoAd videoAd={videoAd} />
        ) : (
          <AdCard
            adTargets={adTargets}
            adSize={[300, 250]}
            adUnit={
              hasLockedBullseyeAd ? "locked_bullseye" : "tap_bullseye_001"
            }
            options={{
              forTopStories: true,
            }}
          />
        )}

        <div className={classes.subscription_box_container}>
          <SubscriptionBox town={town} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Layout5;
