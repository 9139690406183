import React, { useEffect } from "react";
import clsx from "clsx";
import { useStyles } from "./MasterheadMobile.style";
import HamburgerMenuLicenses from "../../../../HamburgerMenuLicenses/HamburgerMenu";
import PreviewBanner from "../PreviewBanner/PreviewBanner";
import withConfig from "../../../../../utils/withConfig";
import Search from "../../../../../common/Icons/Search";
import { sendMessageToParent } from "../../../../../utils/helper";
import ButtonIcon from "../../../../../common/Button/ButtonIcon";
import { urlToUseOnIframe } from "../../../../../utils/halstonMediaHelper";
import MenuButton from "../../../../../common/MenuButton/MenuButton";
import { useTownTheme } from "../../../../../context/TownThemeContext";
import Grid from "../../../../../common/Grid/Grid";
import MasterheadLogo from "../MasterheadLogo";
import { bool, object, string } from "prop-types";
import { useMastheadContext } from "../../../../../context/MastheadContext";
import { useMasthead } from "../../../../../hooks/useMasthead";
import { useTown } from "../../../../../context/TownContext";
import DialogPicker from "../../../../TownPicker/DialogPicker/DialogPicker";
import MobileSearch from "../../../../MobileSearch/MobileSearch";

const OPENED = "opened";
const CLOSED = "closed";

const MasterheadMobile = ({
  legalNoticePermissions,
  townSlug,
  isArticlePreview,
  currentUser,
  isPathUserSignIn,
  section,
  isIframe,
  setOpenMobileSearch,
  openMobileSearch,
}) => {
  const classes = useStyles();
  const { town } = useTownTheme();
  const { mastheadInfo, townsWithClusters } = useMastheadContext();
  const { nearbyTowns } = useTown();
  const { openHamburgerMenu, handleOpenMenu } = useMasthead();

  const toggleMenu = () => {
    handleOpenMenu();
  };

  useEffect(() => {
    const message = openHamburgerMenu ? OPENED : CLOSED;

    sendMessageToParent(
      message,
      urlToUseOnIframe(townSlug, withConfig("HOME_MONGO"))
    );
  }, [openHamburgerMenu]);

  useEffect(() => {
    if (isPathUserSignIn) {
      handleOpenMenu();
    }
  }, []);

  return (
    <>
      {isArticlePreview && <PreviewBanner />}

      <Grid
        style={{
          backgroundColor: "white",
          marginTop: isArticlePreview ? "50px" : "0px",
        }}
        container
        justifyContent="space-evenly"
        className={classes.masterhead_container}
      >
        <Grid
          item
          className={classes.grid_item}
          xs={6}
          style={{ justifyContent: "flex-start" }}
        >
          <MasterheadLogo
            townName={town?.name}
            forMobile
            townSlug={townSlug}
            sectionSlug={section?.slug}
            logoUrl={mastheadInfo?.logo_image}
            customClasses={{ img: isIframe ? classes.iframe_image_logo : "" }}
          />
        </Grid>

        <Grid
          item
          className={classes.grid_item}
          xs={6}
          style={{ justifyContent: "flex-end" }}
        >
          <ButtonIcon
            className={classes.button_icon}
            children={
              <Search
                className={clsx(classes.icons, classes.search_icon)}
                fillColor="black"
              />
            }
            onClick={() => setOpenMobileSearch((prev) => !prev)}
            ariaLabel="Search"
          />

          <DialogPicker
            town={town}
            nearbyTowns={nearbyTowns}
            townsWithClusters={townsWithClusters}
            iconButton
            color="black"
          />

          <MenuButton
            color="black"
            withText={false}
            toggleMenu={toggleMenu}
            useThemeColors={false}
          />
        </Grid>
      </Grid>
      <MobileSearch openState={openMobileSearch} color="white" />

      <HamburgerMenuLicenses
        openState={openHamburgerMenu}
        legalNoticePermissions={legalNoticePermissions}
        toggleMenu={toggleMenu}
        townSlug={townSlug}
        section={section}
        currentUser={currentUser}
        isPathUserSignIn={isPathUserSignIn}
      />
    </>
  );
};

MasterheadMobile.propTypes = {
  legalNoticePermissions: object,
  townSlug: string,
  isArticlePreview: bool,
  currentUser: object,
  isPathUserSignIn: bool,
  section: object,
  isIframe: bool,
};

export default MasterheadMobile;
