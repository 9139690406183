import React, { Fragment, useEffect, useState, useRef, useMemo } from "react";
import clsx from "clsx";
import { useStyles } from "./HamburgerMenu.style";
import MenuDrawer from "../../common/Menu/components/MenuDrawer/MenuDrawer";
import MenuList from "../../common/Menu/components/MenuList/MenuList";
import MenuListItem from "../../common/Menu/components/MenuListItem/MenuListItem";
import TriangleArrowForward from "../../common/Icons/TriangleArrowForward";
import HorizontalDivider from "../../common/HorizontalDivider/HorizontalDivider";
import Typography from "../../common/Typography/Typography";
import labels from "../../config/labels";
import SubmitContentModal from "../Modals/SubmitContentModal/SubmitContentModal";
import SubmenuForDesktop from "./SubmenuForDesktop/SubmenuForDesktop";
import SubmenuForMobile from "./SubmenuForMobile/SubmenuForMobile";
import {
  checkIfIsNotTheLastItem,
  redirectToHomepage,
  redirectToPage,
} from "../../utils/helper";
import Home from "../../common/Icons/Home";
import Button from "../../common/Button/Button";
import OverallButtons from "../Masterhead/LayoutsByVariant/Default/OverallButtons/OverallButtons";
import withConfig from "../../utils/withConfig";
import Editor from "../Editor/Editor";
import theme from "../../common/Theme/themes/default";
import CustomButtons from "../TopNavBar/CustomButtons/CustomButtons";
import { useTownTheme } from "../../context/TownThemeContext";
import { useMasthead } from "../../hooks/useMasthead";
import {
  parseSubmenuItemsForDesktop,
  itemHasSubmenu,
  isSelectedMenuItem,
} from "../../services/hamburgerMenu";
import AdminButtons from "./AdminButtons/AdminButtons";
import { bool, func, object, string } from "prop-types";
import DialogPicker from "../TownPicker/DialogPicker/DialogPicker";
import { screenWidthIsHigherThan } from "../../hooks/useMediaQuery";
import values from "../../common/Theme/themes/breakpoints/values";
import { useMastheadContext } from "../../context/MastheadContext";
import { useTown } from "../../context/TownContext";

const WHITE = "white";
const TOP_TARGET = "_top";
const BLANK_TARGET = "_blank";
const DEFAULT_ITEMS_WITH_SUBMENU = ["News", "Admin", "Opinion"];

const HamburgerMenu = ({
  openState,
  legalNoticePermissions,
  toggleMenu,
  townSlug,
  sectionSlug,
  currentUser,
  isPathUserSignIn,
  overallPage,
}) => {
  const classes = useStyles();
  const [mainMenuRef, setMainMenuRef] = useState(null);
  const referenceForSubmenu = useRef();
  const [subMenuOptions, setSubMenuOptions] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState();
  const [buttons, setButtons] = useState();
  const [openSubmitContentPopUp, setOpenSubmitContentPopUp] = useState(false);
  const { town, firstBlockButtons, signUpPath } = useTownTheme();
  const { nearbyTowns } = useTown();
  const { sticky: stickyMasthead } = useMasthead();
  const {
    hamburgerMenuItems,
    adminMenuItems,
    mastheadInfo,
    townsWithClusters,
  } = useMastheadContext();
  const {
    palette: {
      themeColor: { primary },
    },
  } = theme;
  const isDesktop = screenWidthIsHigherThan(values.md);
  const showAdditionalItems = useMemo(
    () => !overallPage && (!isDesktop || (isDesktop && stickyMasthead)),
    [stickyMasthead, isDesktop]
  );

  const closeDesktopSubmenu = () => {
    setMainMenuRef(null);
    setSelectedItemId();
  };

  const handleMenuItemClick = (item) => {
    if (selectedItemId === item.id) {
      setSelectedItemId();
      setSubMenuOptions([]);
    } else {
      setSelectedItemId(item.id);
    }
    if (item?.submenu_items?.length > 0) {
      if (isDesktop) {
        setSubMenuOptions(parseSubmenuItemsForDesktop(item));
      } else {
        setSubMenuOptions(item.submenu_items);
      }
    } else if (item?.submenu_items?.length === 0) {
      window.open(
        item.path,
        item.id === "advertise" || item.id === "franchise"
          ? BLANK_TARGET
          : TOP_TARGET
      );
    }
  };

  const openDesktopSubmenu = (item, e) => {
    handleMenuItemClick(item);
    if (mainMenuRef !== e.currentTarget) {
      setMainMenuRef(e.currentTarget);
    }
  };

  const isDefaultItemWithEmptySubmenu = (item) => {
    return (
      DEFAULT_ITEMS_WITH_SUBMENU.includes(item.name) && !itemHasSubmenu(item)
    );
  };

  const handleButtonClick = (button) => {
    if (button.action === "default_submit") {
      setOpenSubmitContentPopUp(true);
      return;
    }

    if (button.action === "default_newsletter_signup") {
      redirectToPage(signUpPath, TOP_TARGET);
      return;
    }

    redirectToPage(button.action, TOP_TARGET);
    return;
  };

  useEffect(() => {
    setButtons(
      firstBlockButtons.filter((button) => !button.action.includes("login"))
    );

    return () => setButtons();
  }, [firstBlockButtons]);

  return (
    <MenuDrawer
      anchor="left"
      openState={openState}
      onClose={toggleMenu}
      variant="temporary"
      classes={{
        paper: classes.drawer_paper,
        root: classes.drawer_root,
      }}
    >
      <MenuList disablePadding={true}>
        {showAdditionalItems && !overallPage && (
          <MenuListItem
            disableGutters={false}
            classes={{
              gutters: classes.editor_gutters,
            }}
          >
            <Editor
              townId={townSlug}
              sectionId={sectionSlug}
              mobile
              editor={mastheadInfo?.editor}
            />
          </MenuListItem>
        )}

        {!isDesktop && stickyMasthead && (
          <MenuList disablePadding classes={{ root: classes.buttons_list }}>
            <DialogPicker
              nearbyTowns={nearbyTowns}
              townsWithClusters={townsWithClusters}
            />
          </MenuList>
        )}

        {!overallPage && (
          <>
            <MenuListItem
              onClick={() => redirectToHomepage(townSlug)}
              title={labels.HOME}
              disableGutters={false}
              classes={{
                root: classes.home_menu_item,
                gutters: classes.menu_item_gutters,
              }}
            >
              <Typography level="paragraph_1" color={WHITE}>
                {labels.HOME}
              </Typography>
              <Home className={classes.home_icon} />
            </MenuListItem>
            <HorizontalDivider className={classes.divider} />
          </>
        )}

        {hamburgerMenuItems.map((item, index) => {
          if (!isDefaultItemWithEmptySubmenu(item)) {
            return (
              <Fragment key={`menu-fragment-${index}`}>
                <MenuListItem
                  title={item.name}
                  innerRef={referenceForSubmenu}
                  classes={{
                    root: classes.menu_item,
                    gutters: classes.menu_item_gutters,
                  }}
                  disableGutters={false}
                  key={`menu-list-item-${index}`}
                  onClick={() => handleMenuItemClick(item)}
                  divider={false}
                  {...(isDesktop &&
                    itemHasSubmenu(item) && {
                      onMouseEnter: (e) => openDesktopSubmenu(item, e),
                    })}
                >
                  <Typography level="paragraph_1" color={WHITE}>
                    {item.name}
                  </Typography>
                  {itemHasSubmenu(item) && (
                    <TriangleArrowForward
                      className={clsx(
                        isSelectedMenuItem(selectedItemId, item) &&
                          !isDesktop &&
                          classes.rotated_arrow,
                        classes.arrow
                      )}
                      fillColor={primary}
                    />
                  )}
                </MenuListItem>
                {checkIfIsNotTheLastItem(index, hamburgerMenuItems) && (
                  <HorizontalDivider
                    className={clsx(
                      classes.divider,
                      isSelectedMenuItem(selectedItemId, item) &&
                        !isDesktop &&
                        classes.full_width_divider
                    )}
                  />
                )}
                {isSelectedMenuItem(selectedItemId, item) && (
                  <>
                    {isDesktop ? (
                      <SubmenuForDesktop
                        mainMenuRef={mainMenuRef}
                        onClose={closeDesktopSubmenu}
                        submenuBlocks={subMenuOptions}
                        onItemClick={handleMenuItemClick}
                        title={item.name}
                      />
                    ) : (
                      <SubmenuForMobile
                        subMenuItems={subMenuOptions}
                        onItemClick={handleMenuItemClick}
                      />
                    )}
                  </>
                )}
              </Fragment>
            );
          }
        })}
      </MenuList>

      {!overallPage && (
        <>
          <MenuList disablePadding classes={{ root: classes.buttons_list }}>
            {buttons?.length > 0 &&
              buttons.map((button) => (
                <Fragment key={button.action}>
                  <Button
                    level="simple_button"
                    color="primary"
                    onClick={() => handleButtonClick(button)}
                    children={button.label}
                    classes={{
                      root: classes.button,
                      label: classes.button_label,
                    }}
                    style={{
                      backgroundColor: button.background_color,
                      color: button.label_color,
                    }}
                    ariaLabel={button.label}
                    dataCy={button.label}
                  />
                </Fragment>
              ))}

            <CustomButtons />
          </MenuList>
        </>
      )}

      {overallPage && !isDesktop && (
        <OverallButtons
          signUpLink={withConfig("NEW_SUBSCRIPTION")}
          handleSubmitContentClick={() => setOpenSubmitContentPopUp(true)}
        />
      )}

      {!overallPage && (
        <AdminButtons
          currentUser={currentUser}
          adminMenuItems={adminMenuItems}
          townsWithClusters={townsWithClusters}
          isPathUserSignIn={isPathUserSignIn}
        />
      )}

      <SubmitContentModal
        town={{ id: town?.id }}
        handleClose={() => setOpenSubmitContentPopUp(false)}
        openState={openSubmitContentPopUp}
        legalNoticePermissions={legalNoticePermissions}
      />
    </MenuDrawer>
  );
};

HamburgerMenu.propTypes = {
  openState: bool,
  legalNoticePermissions: object,
  toggleMenu: func,
  townSlug: string,
  sectionSlug: string,
  currentUser: object,
  isPathUserSignIn: bool,
  overallPage: bool,
};

HamburgerMenu.defaultProps = {
  overallPage: false,
};

export default HamburgerMenu;
