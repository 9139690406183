import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import { useStyles } from "./MasterheadLogo.styles";
import { getLogoUrl } from "../../../../utils/helper";
import TapIntoLogo from "../../../../common/Icons/Vectors/Logos/TapIntoLogo";
import { useTownTheme } from "../../../../context/TownThemeContext";
import Link from "../../../../common/Link/Link";
import Grid from "../../../../common/Grid/Grid";
import Typography from "../../../../common/Typography/Typography";
import withConfig from "../../../../utils/withConfig";
import theme from "../../../../common/Theme/themes/default";

const MasterheadLogo = ({
  townName,
  townSlug,
  sectionSlug,
  logoUrl,
  forOverallPage,
  overallLogoClasses,
  forMobile,
  displaySlogan,
  customClasses,
  reduced,
}) => {
  const classes = useStyles();
  const { themeColors } = useTownTheme() || {};
  const { colorName } = theme || {};

  const LinkWithTownName = () => {
    return (
      <Link
        url={getLogoUrl(withConfig("HOME"), townSlug, sectionSlug)}
        children={
          <Grid
            container
            justifyContent="center"
            className={classes.town_name_logo_grid}
          >
            <Typography
              color="tapintoGreen"
              bold
              className={clsx(
                classes.header,
                reduced && classes.reduced_header,
                customClasses?.header && customClasses?.header
              )}
            >
              {labels.TAP}
            </Typography>
            <Typography
              color="tapintoOrange"
              className={clsx(
                classes.header,
                classes.margin_right,
                reduced && classes.reduced_header,
                customClasses?.header && customClasses?.header
              )}
            >
              {labels.INTO}
            </Typography>
            {townName && (
              <Typography
                color="tapintoGreen"
                bold
                className={clsx(
                  classes.header,
                  townName.length >= 15 && classes.title_long,
                  reduced && classes.reduced_header,
                  customClasses?.header && customClasses?.header
                )}
              >
                {townName}
              </Typography>
            )}
            {displaySlogan && !forMobile && (
              <Typography
                color={
                  colorName[
                    themeColors?.mathead_colors?.contrast?.toUpperCase()
                  ] || "white"
                }
                level="paragraph_1"
                className={clsx(classes.slogan, customClasses?.slogan)}
              >
                {labels.SLOGAN}
              </Typography>
            )}
          </Grid>
        }
        target="_top"
      />
    );
  };

  if (forOverallPage) {
    return (
      <a href="/" target="_top" className={classes.overall_logo_link}>
        <TapIntoLogo classes={overallLogoClasses} />
      </a>
    );
  }

  return (
    <>
      {logoUrl ? (
        <div
          className={clsx(
            classes.logo_img_container,
            reduced && classes.reduced_logo_padding
          )}
        >
          <a
            href={getLogoUrl(withConfig("HOME"), townSlug, sectionSlug)}
            target="_top"
            className={classes.masterhead_link}
          >
            <img
              className={clsx(
                classes.logo_img,
                reduced && classes.reduced_logo_img,
                customClasses?.img && customClasses.img
              )}
              src={logoUrl}
            />
          </a>
        </div>
      ) : (
        <LinkWithTownName />
      )}
    </>
  );
};

MasterheadLogo.propTypes = {
  townName: PropTypes.string,
  townSlug: PropTypes.string,
  sectionSlug: PropTypes.string,
  logoUrl: PropTypes.string,
  forOverallPage: PropTypes.bool,
  overallLogoClasses: PropTypes.shape({
    container: PropTypes.string,
    tapinto: PropTypes.string,
    slogan: PropTypes.string,
  }),
  customClasses: PropTypes.shape({
    header: PropTypes.string,
    slogan: PropTypes.string,
    img: PropTypes.string,
  }),
  forMobile: PropTypes.bool,
  displaySlogan: PropTypes.bool,
  reduced: PropTypes.bool,
};

MasterheadLogo.defaultProps = {
  forOverallPage: false,
  forMobile: false,
  displaySlogan: true,
  reduced: false,
};

export default MasterheadLogo;
